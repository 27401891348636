import { Injectable } from "@angular/core";
import { Observable, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { CacheService } from "../cache.service";

import { HttpClient } from "../http-client";
import { PartnerAccountExistsResponse } from "../responses/partner-account-exists-response";
import { PartnerPendingAccountAccessRequestResponse } from "../responses/partner-pending-account-access-request-response";

import { PartnerService } from "./partner.service";

export enum PartnerAccessRequestAction {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

@Injectable()
export class PartnerAccessRequestService {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    public partnerService: PartnerService,
  ) {}

  private getCurrentPartnerId(): number {
    return this.partnerService.currentPartnerId;
  }

  checkAccountExists(
    companyName: string,
    companyAdditionalInformation: string,
    billingAddressStreetAndNumber: string,
    billingAddressPostalCode: string,
    billingAddressCity: string,
    billingAddressCountry: string,
    vatNumber: string,
  ): Observable<PartnerAccountExistsResponse[]> {
    return this.http
      .post(`partner_account_exists`, {
        companyName: companyName,
        companyAdditionalInformation: companyAdditionalInformation,
        billingAddressStreetAndNumber: billingAddressStreetAndNumber,
        billingAddressPostalCode: billingAddressPostalCode,
        billingAddressCity: billingAddressCity,
        billingAddressCountry: billingAddressCountry,
        vatNumber: vatNumber,
      })
      .pipe(
        map(({ body }) =>
          body.map(
            (row: any) =>
              new PartnerAccountExistsResponse(
                row.id,
                row.companyName,
                row.companyAdditionalInformation,
                row.billingAddressStreetAndNumber,
                row.billingAddressPostalCode,
                row.billingAddressCity,
                row.billingAddressCountry,
                row.vatNumber,
              ),
          ),
        ),
      );
  }

  requestPartnerAccountAccess(partnerId: number): Observable<void> {
    return this.http
      .post(`partner_account_access_request`, { partnerId: partnerId })
      .pipe(map(() => {}));
  }

  handlePartnerAccountAccessRequest(
    accountAccessRequestId: number,
    status: PartnerAccessRequestAction,
  ): Observable<void> {
    return this.http
      .put(`partner_account_access_request/${accountAccessRequestId}`, {
        status: status,
      })
      .pipe(map(() => {}));
  }

  checkForPartnerPendingAccountAccessRequest(): Promise<PartnerPendingAccountAccessRequestResponse> {
    const partnerId = this.getCurrentPartnerId();
    const url = `partner/${partnerId}/account_pending_access_request`;

    return this.cacheService.getCachedData<PartnerPendingAccountAccessRequestResponse>(
      url,
      () =>
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map(
                ({ body }) =>
                  new PartnerPendingAccountAccessRequestResponse(
                    body.hasPending,
                    body.accountAccessRequestId,
                    body.userEmail,
                  ),
              ),
            ),
        ),
    );
  }
}
