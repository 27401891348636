export class PartnerAccountExistsResponse {
  constructor(
    public readonly id: number,
    public readonly companyName: string,
    public readonly companyAdditionalInformation: string,
    public readonly billingAddressStreetAndNumber: string,
    public readonly billingAddressPostalCode: string,
    public readonly billingAddressCity: string,
    public readonly billingAddressCountry: string,
    public readonly vatNumber: string,
  ) {}
}
